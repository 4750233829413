"use client"

import { handleSignIn } from "@/actions/public/sign-in.action"
import { Button } from "../ui/button"
import { cn } from "@/lib/utils"

interface SignInProps {
    redirectTo?: string
    className?: string
    variant?: "default" | "outline"
    size?: "default" | "sm" | "lg" | "icon"
    children?: React.ReactNode
}

export function SignIn({
    redirectTo = "/",
    className,
    variant = "default",
    size = "default",
    children
}: SignInProps) {
    return (
        <form action={() => handleSignIn(redirectTo)}>
            <Button className={cn("w-full", className)} variant={variant} size={size} type="submit">
                {children || "New Dashboard"}
            </Button>
        </form>
    )
}
