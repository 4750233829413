import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
    /**
     * Specify your server-side environment variables schema here.
     */
    server: {
        NODE_ENV: z.enum(["development", "test", "production"]),
        VERCEL_URL: z.string().optional(),
        VERCEL_ENV: z.enum(["development", "preview", "production"]),
        PORT: z.string().min(1),
        AUTH_SECRET: process.env.NODE_ENV === "production" ? z.string().min(1) : z.string().min(1).optional(),
        //        AUTH_URL: z.preprocess(
        // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
        // Since NextAuth.js automatically uses the VERCEL_URL if present.
        //            (str) => process.env.VERCEL_URL ?? str,
        // VERCEL_URL doesn't include `https` so it cant be validated as a URL
        //           process.env.VERCEL ? z.string().min(1) : z.string().url()
        //       ),
        SITE_URL: z.string().url(),
        // Add `.min(1) on ID and SECRET if you want to make sure they're not empty
        COGNITO_CLIENT_ID: z.string().min(1),
        COGNITO_CLIENT_SECRET: z.string().min(1),
        COGNITO_ISSUER: z.string().url().min(1),
        COGNITO_DOMAIN: z.string().url().min(1),
        USER_POOL_ID: z.string().min(1),
        OPERATIONS_API_KEY: z.string().min(1),
        AWS_ACCESS_KEY_ID: z.string().min(1),
        AWS_SECRET_ACCESS_KEY: z.string().min(1),
        AWS_REGION: z.string().min(1),
        TCE_STAGE: z.enum(["prod", "tom"]),
        ELASTIC_SEARCH: z.string().url(),
        TEST_USER_EMAIL: process.env.NODE_ENV === "production" ? z.string().optional() : z.string().min(1),
        TEST_USER_PASSWORD: process.env.NODE_ENV === "production" ? z.string().optional() : z.string().min(1),
        OPENAI_API_KEY: z.string().min(1),
        OPENAI_CONTENT_ASSISTANT_ID: z.string().min(1),
        DEEPL_AUTH_KEY: z.string().min(1),
        HCAPTCHA_SECRET_KEY: z.string().min(1),
        FAIRAI_API_KEY: z.string().min(1),
        KV_REST_API_READ_ONLY_TOKEN: z.string().min(1),
        KV_REST_API_TOKEN: z.string().min(1),
        KV_REST_API_URL: z.string().min(1),
        KV_URL: z.string().min(1),
        QSTASH_URL: z.string().min(1),
        QSTASH_TOKEN: z.string().min(1),
        QSTASH_CURRENT_SIGNING_KEY: z.string().min(1),
        QSTASH_NEXT_SIGNING_KEY: z.string().min(1),
        UPSTASH_WORKFLOW_URL: z.string().url().optional(),
        BENTO_PUBLISHABLE_KEY: z.string().min(1),
        BENTO_SECRET_KEY: z.string().min(1),
        PARADE_URL: z.string().url(),
        RSS_FEED_SECRET: z.string().min(1)
    },

    /**
     * Specify your client-side environment variables schema here. To expose them to the client, prefix them with
     * `NEXT_PUBLIC_`.
     */
    client: {
        NEXT_PUBLIC_VERCEL_ENV: z.enum(["development", "preview", "production"]),
        NEXT_PUBLIC_HCAPTCHA_SITE_KEY: z.string().min(1),
        NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
        NEXT_PUBLIC_POSTHOG_HOST: z.string().min(1),
        NEXT_PUBLIC_BENTO_SITE_KEY: z.string().min(1)
    },

    /**
     * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
     * middlewares) or client-side so we need to destruct manually.
     */
    runtimeEnv: {
        NODE_ENV: process.env.NODE_ENV,
        VERCEL_ENV: process.env.VERCEL_ENV,
        NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV || "development",
        VERCEL_URL: process.env.VERCEL_URL,
        PORT: process.env.PORT || "3000",
        AUTH_SECRET: process.env.AUTH_SECRET,
        // AUTH_URL: process.env.AUTH_URL,
        SITE_URL: process.env.SITE_URL,
        COGNITO_CLIENT_ID: process.env.COGNITO_CLIENT_ID,
        COGNITO_CLIENT_SECRET: process.env.COGNITO_CLIENT_SECRET,
        COGNITO_ISSUER: process.env.COGNITO_ISSUER,
        COGNITO_DOMAIN: process.env.COGNITO_DOMAIN,
        USER_POOL_ID: process.env.USER_POOL_ID,
        OPERATIONS_API_KEY: process.env.OPERATIONS_API_KEY,
        AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
        AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
        AWS_REGION: process.env.AWS_REGION || "eu-central-1",
        TCE_STAGE: process.env.TCE_STAGE || "prod",
        ELASTIC_SEARCH: process.env.ELASTIC_SEARCH,
        TEST_USER_EMAIL: process.env.TEST_USER_EMAIL,
        TEST_USER_PASSWORD: process.env.TEST_USER_PASSWORD,
        NEXT_PUBLIC_HCAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY,
        NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
        NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        HCAPTCHA_SECRET_KEY: process.env.HCAPTCHA_SECRET_KEY,
        OPENAI_API_KEY: process.env.OPENAI_API_KEY,
        OPENAI_CONTENT_ASSISTANT_ID: process.env.OPENAI_CONTENT_ASSISTANT_ID,
        DEEPL_AUTH_KEY: process.env.DEEPL_AUTH_KEY,
        FAIRAI_API_KEY: process.env.FAIRAI_API_KEY,
        KV_REST_API_READ_ONLY_TOKEN: process.env.KV_REST_API_READ_ONLY_TOKEN,
        KV_REST_API_TOKEN: process.env.KV_REST_API_TOKEN,
        KV_REST_API_URL: process.env.KV_REST_API_URL,
        KV_URL: process.env.KV_URL,
        QSTASH_URL: process.env.QSTASH_URL,
        QSTASH_TOKEN: process.env.QSTASH_TOKEN,
        QSTASH_CURRENT_SIGNING_KEY: process.env.QSTASH_CURRENT_SIGNING_KEY,
        QSTASH_NEXT_SIGNING_KEY: process.env.QSTASH_NEXT_SIGNING_KEY,
        UPSTASH_WORKFLOW_URL: process.env.UPSTASH_WORKFLOW_URL,
        NEXT_PUBLIC_BENTO_SITE_KEY: process.env.NEXT_PUBLIC_BENTO_SITE_KEY,
        BENTO_PUBLISHABLE_KEY: process.env.BENTO_PUBLISHABLE_KEY,
        BENTO_SECRET_KEY: process.env.BENTO_SECRET_KEY,
        PARADE_URL: process.env.PARADE_URL,
        RSS_FEED_SECRET: process.env.RSS_FEED_SECRET
    },
    /**
     * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
     * This is especially useful for Docker builds.
     */
    skipValidation: Boolean(process.env.SKIP_ENV_VALIDATION)
})

export const isProduction = env.NEXT_PUBLIC_VERCEL_ENV === "production"
export const isDevelopment = env.NEXT_PUBLIC_VERCEL_ENV === "development"
export const whichEnvironment = isProduction ? "production" : "development"
