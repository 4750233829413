"use client"

import {
    LogOut,
    Settings,
    User,
    LayoutDashboard,
    AppWindow,
    Sparkles,
    ChevronDown,
    HomeIcon,
    DollarSign,
    Search
} from "lucide-react"
import Link from "next/link"
import { useSession } from "next-auth/react"
import type { Session } from "next-auth"
import { cn, isStaffEmail } from "@/lib/utils"
import { TCELogo } from "../icons/tce-logo"
import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { signOutAction } from "@/actions/public/signout"
import { SignIn } from "@/components/auth/signin"
import { Suspense } from "react"

function DropdownButton({ session }: { session: Session | null }) {
    const imageBackground = session?.user.isInsider
        ? "bg-gray-100 dark:bg-gray-800 border-2 border-tce-400"
        : "bg-gray-200 dark:bg-gray-700"

    return (
        <Button variant="outline" className="h-auto gap-2 hover:bg-stone-200 px-2 ml-1 lg:ml-0 lg:px-4">
            <div className="flex items-center">
                <div className="hidden xl:inline-block">
                    <TCELogo
                        variant="default"
                        icon
                        className={cn("h-10 w-10 rounded-full p-1 mr-3", imageBackground)}
                    />
                </div>
                <div className="hidden lg:inline-block">
                    {session?.user?.name && (
                        <p className="w-full max-w-[200px] truncate text-sm font-medium text-gray-700 dark:text-gray-300">
                            {session?.user?.name}
                        </p>
                    )}
                    <p className="text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                        {session?.user?.email}
                    </p>
                </div>
                <div className="lg:hidden">
                    <User className="size-5 text-gray-600 dark:text-gray-400" aria-hidden="true" />
                </div>
                <ChevronDown
                    className="-mr-1 ml-0 lg:ml-2 size-5 text-gray-400 dark:text-gray-600"
                    aria-hidden="true"
                />
            </div>
        </Button>
    )
}

function AvatarContent() {
    const { data: session, status } = useSession()
    const router = useRouter()
    const pathname = usePathname()
    const searchParams = useSearchParams()

    if (!session) {
        if (pathname === "/signup") return null
        const callback = searchParams.get("callback")
        const redirectPath = callback
            ? decodeURIComponent(callback)
            : pathname === "/"
              ? "/dashboard"
              : pathname
        return (
            <div className="flex gap-1 lg:gap-3">
                <SignIn redirectTo={redirectPath} variant="outline">
                    Sign In
                </SignIn>
                <Button id="loginbutton" variant="default" href="/signup">
                    Sign Up
                    <span className="hidden xl:inline">&nbsp;for Free</span>
                </Button>
            </div>
        )
    }

    const menuSections = [
        {
            items: [
                {
                    href: "/account/profile",
                    label: "Profile",
                    icon: User,
                    id: "account-details",
                    className: ""
                },
                {
                    href: "/account/purchases",
                    label: "Purchases",
                    icon: DollarSign,
                    id: "purchases",
                    className: ""
                },
                {
                    href: "/dashboard",
                    label: "Dashboard",
                    icon: LayoutDashboard,
                    id: "dashboard"
                },
                {
                    href: "/search",
                    label: "Search",
                    icon: Search,
                    id: "search"
                }
            ]
        }
    ]

    if (pathname === "/dashboard") {
        menuSections.unshift({
            items: [{ href: "/", label: "Homepage", icon: HomeIcon, id: "homepage" }]
        })
    }

    if (session.user.email && isStaffEmail(session.user.email)) {
        menuSections.unshift({
            items: [
                {
                    href: "/operations",
                    label: "Operations",
                    icon: Settings,
                    id: "operations",
                    className: "text-violet-700 dark:text-violet-300"
                }
            ]
        })
    }

    if (session.user.isInsider) {
        menuSections.push({
            items: [{ href: "/generate", label: "Generate New Content", icon: Sparkles, id: "generate" }]
        })
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <div>
                    <DropdownButton session={session} />
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-56">
                <div className="px-4 py-3 lg:hidden">
                    <p className="text-sm font-light text-muted-foreground">Signed in as</p>
                    <p className="truncate text-sm font-medium">{session.user.email}</p>
                </div>
                {menuSections.map((section, sectionIndex) => (
                    <div key={sectionIndex}>
                        {section.items.map((item) => (
                            <DropdownMenuItem key={item.id} asChild className="py-3">
                                <Link href={item.href} className={cn("flex items-center", item.className)}>
                                    <item.icon className="mr-3 size-5 text-tce-500 dark:text-tce-400" />
                                    {item.label}
                                </Link>
                            </DropdownMenuItem>
                        ))}
                        <DropdownMenuSeparator />
                    </div>
                ))}
                <DropdownMenuItem
                    className="cursor-pointer text-red-600 focus:bg-red-50 focus:text-red-600 dark:text-red-400 dark:focus:bg-red-950 dark:focus:text-red-400"
                    onClick={signOutAction}
                >
                    <LogOut className="mr-3 size-5" />
                    Sign out
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default function AvatarDropdownMenu() {
    return (
        <Suspense>
            <AvatarContent />
        </Suspense>
    )
}
